<template>
  <div class="wrap">
	<my-header></my-header>
	<div class="section">
		<div class="banner">
			<p>公司动态</p>
			<div></div>
		</div>
		<div class="content">
			
		</div>
	</div>
	<my-footer></my-footer>
  </div>
</template>

<script>
// @ is an alias to /src   
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'

export default {
  name: 'Home',
  components: {
    MyHeader,MyFooter
  },
  data(){
	  return{
	  }
  },
  methods:{
  },
  mounted() {
  }
}
</script>
<style scoped>
	.banner{
		width: 100%;
		height: 5.76rem;
		padding:1.8rem 3.6rem 0;
		font-size: 0.22rem;
		font-weight: 300;
		background: url(../../public/images/dynamic/banner.png) no-repeat;
		background-size: 100% 100%;
		line-height: 2;
	}
	.banner p{
		font-size: 0.54rem;
		line-height:1.39 ;
		margin-bottom: 0.18rem;
	}
	.content{
		padding:1rem 3.6rem;
		color:#333;
	}
</style>
